var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.orderStatus == _vm.orderConstants.STATUS_CREATED)?[_c('v-row',[_c('v-col',[_c('custom-form-action-btn',{attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'submit',
            dynamicAttr: {
              name: 'status',
              value: _vm.orderConstants.STATUS_REJECT,
            },
          },"label":"拒絕","outlined":"","color":"primary","cols":{cols: 6}}})],1),_c('v-col',[_c('custom-form-action-btn',{attrs:{"defaultKey":_vm.defaultKey,"action":{
            key: 'submit',
            dynamicAttr: {
              name: 'status',
              value: _vm.orderConstants.STATUS_APPLY,
            },
          },"label":"接單","color":"primary","cols":{cols: 6}}})],1)],1)]:_vm._e(),(_vm.orderStatus == _vm.orderConstants.STATUS_APPLY)?[_c('custom-form-action-btn',{attrs:{"defaultKey":_vm.defaultKey,"action":{
        key: 'submit',
        dynamicAttr: { name: 'status', value: _vm.orderConstants.STATUS_SENDED },
      },"label":"出貨","color":"primary"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }