<template>
  <v-dialog
    v-model="openOrderAdjustmentCheckDialog" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card class="linerp-layout">
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="調整內容" class="primary--text" />
        <v-btn @click="handleOrderAdjustmentCheckDialogSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container v-if="orderData">
        <v-row class="mx-1 mb-3 border-lightGray-all border-radius-primary" v-for="(itemV) in orderCardData" :key="itemV.item_id">
          <v-col cols="12" class="px-3">
            <page-title :title="itemV.product" />
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2" style="border-radius: 0;">
              <div class="py-2 pl-3">規格：{{ itemV.per_shipping_count_text }}</div>
            </v-card>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2" style="border-radius: 0;">
              <div class="py-2 pl-3">單價：{{ itemV.per_unit_price }}</div>
            </v-card>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2 white--text primary border-0" style="border-radius: 0;">
              <div class="py-2 pl-3">原始數量：{{ itemV.origin_count }}{{ itemV.shipping_unit }}</div>
            </v-card>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2 red--text" style="border-radius: 0; background-color: #DFEDE9;">
              <div class="py-2 pl-3">*調整數量：{{ itemV.modify_count }}{{ itemV.shipping_unit }}</div>
            </v-card>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2 white--text primary" style="border-radius: 0;">
              <div class="py-2 pl-3">原始總計：{{ itemV.origin_total }}元</div>
            </v-card>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2 red--text" style="border-radius: 0; background-color: #DFEDE9;">
              <div class="py-2 pl-3">*調整總計：{{ itemV.modify_total }}元</div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2" style="border-radius: 0;">
              <div class="py-2 d-flex justify-center">原始總計：{{ originTotalSum }}元</div>
            </v-card>
          </v-col>
          <v-col cols="6" class="py-0 px-0">
            <v-card elevation="0" class="px-0 py-2 red--text" style="border-radius: 0;">
              <div class="py-2 d-flex justify-center">*調整總計：{{ modifyTotalSum }}元</div>
            </v-card>
          </v-col>
        </v-row>
        <div class="pa-4 d-flex justify-space-around">
          <v-btn color="primary" outlined @click="handleClickCheckAdjustOrder('reject')">拒絕</v-btn>
          <v-btn color="primary" @click="handleClickCheckAdjustOrder('apply')">接單</v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import orderItemMixins from "@/mixins/orderItem.js";
export default {
  mixins: [orderItemMixins],
  components: {
  },
  props: {
    openOrderAdjustmentCheckDialog: {
      type: Boolean,
      required: true,
    },
    orderData: {
      type: Object,
      required: true,
    },
    providerId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    orderCardData: [],
    originTotalSum: 0,
    modifyTotalSum: 0
  }),
  watch : {
    orderData: {
      deep: true,
      async handler() {
        if(!this.orderData?.items) return
        this.orderCardData = this.orderData.items.map(item => {
          return {
            product: item.product,
            shipping_unit: item.shipping_unit, // 出貨單位
            calculate_unit: item.calculate_unit, // 計價單位
            per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
            shipping_count: this.$helper.reverseShippingCount(item.shipping, item.shipping_unit), // 出貨數量
            origin_price: item.origin_price,
            price: this.editAble ? Number(this.getInitialPrice(item)) : Number(item.price), // 單價
            order_count: Number(this.$helper.reverseShippingCount(item.count, item.shipping_unit)), // 訂購數量 / 數量
            per_shipping_count_text: `${item.per_shipping_count}${item.calculate_unit}/${item.shipping_unit}`, //規格
            per_unit_price: `${item.price}元/${item.calculate_unit}`,
            origin_count: `${item.adjustmentOriginCount}`,
            modify_count: `${item.adjustmentModifyCount}`,
            origin_total: `${item.adjustmentOriginTotal}`,
            modify_total: `${item.adjustmentModifyTotal}`,
            item_id: item.item_id,
          }
        })
        await this.$helper.delay(0.5)
      }
    },
    orderCardData: {
      deep: true,
      async handler(data) {
        await this.$helper.delay(0.5)
        this.originTotalSum = Math.round(data.reduce((arr, cur) => {
          if (cur.origin_total) arr += Number(cur.origin_total)
          return arr
        }, 0))
        this.modifyTotalSum = Math.round(data.reduce((arr, cur) => {
          if (cur.modify_total) arr += Number(cur.modify_total)
          return arr
        }, 0))
      },
    },
    
  },
  methods: {
    handleOrderAdjustmentCheckDialogSwitch(isOpen) {
      this.$emit('updateOrderAdjustmentCheckDialogSwitch', isOpen)
    },
    async handleClickCheckAdjustOrder(status) {
      this.$store.dispatch("loading/active")
      try {
        await this.$api.collection.providerApi.putLastOrderAdjustmentStatus(this.providerId, this.orderData.order_no, { status });
        this.$snotify.success(`調整成功`)
        this.handleOrderAdjustmentCheckDialogSwitch(false)
      } catch (e) {
        this.$snotify.error(`調整失敗，請稍後再試`);
      } finally{
        this.$emit('updateOrderAdjustmentSuccess')
        this.$store.dispatch("loading/close")
      }
    },
  },
};
</script>
