<template >
  <div>
    <!-- 新訂單 -->
    <template v-if="orderStatus == orderConstants.STATUS_CREATED">
      <v-row>
        <v-col>
          <custom-form-action-btn
            :defaultKey="defaultKey"
            :action="{
              key: 'submit',
              dynamicAttr: {
                name: 'status',
                value: orderConstants.STATUS_REJECT,
              },
            }"
            label="拒絕"
            outlined
            color="primary"
            :cols="{cols: 6}"
          ></custom-form-action-btn>
        </v-col>
        <v-col>
          <custom-form-action-btn
            :defaultKey="defaultKey"
            :action="{
              key: 'submit',
              dynamicAttr: {
                name: 'status',
                value: orderConstants.STATUS_APPLY,
              },
            }"
            label="接單"
            color="primary"
            :cols="{cols: 6}"
          ></custom-form-action-btn>
        </v-col>
      </v-row>

      <!-- <custom-form-action-btn :defaultKey="defaultKey" :action="{key: 'click',handler : refreshForm }"  label="reset" ></custom-form-action-btn>
            <custom-form-action-btn :defaultKey="defaultKey" :action="{key: 'reset'}"  label="reset" ></custom-form-action-btn> -->
    </template>
    <!-- 已接單 -->
    <template v-if="orderStatus == orderConstants.STATUS_APPLY">
      <custom-form-action-btn
        :defaultKey="defaultKey"
        :action="{
          key: 'submit',
          dynamicAttr: { name: 'status', value: orderConstants.STATUS_SENDED },
        }"
        label="出貨"
        color="primary"
      ></custom-form-action-btn>
    </template>
    <!-- $parent.isSubmit -->
  </div>
</template>

<script lang="babel" type="text/babel">

import ItemMixin from '@/components/form/custom/ItemMixin.js'
import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue"
import orderConstants from "@/modules/base/config/orderConstants"

export default {
    components : {
        customFormActionBtn
    },
    mixins: [ItemMixin],
    props : {
        orderStatus : String,
        defaultKey : String
    },
    data: () => ({
        orderConstants : orderConstants
    }),
    async created() { 
    
    },
    watch : {
        
    },
    computed: {


    },
    methods: {
        // 強制還原表單內容
        refreshForm() {
            this.$parent.$emit('forceRefresh') 
        }
    },
}
</script>

