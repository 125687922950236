<template >
  <div>
    <form-info :value="orderInfo" class="mb-3 py-3 "/>

    <bar class="mb-5" type="primary">
      <template v-slot:label> 訂單編號 </template>
      <template v-slot:value>{{ order_no }}</template>
    </bar>

    <form-info class="mb-5" :value="storeInfo" hasBorder />
  </div>
</template>

<script lang="babel" type="text/babel">

import ItemMixin from '@/components/form/custom/ItemMixin.js'
import orderConstants from "@/modules/base/config/orderConstants"

export default {
    mixins: [ItemMixin],
    components: {
        formInfo: () => import('@/components/form/custom/formInfo.vue'),
        bar: () => import('@/modules/base/components/bar/bar.vue'),
    },
    data: () => ({
        data : null,
        orderConstants : orderConstants
    }),
    async created() { 

    },
    watch : {
        propsValue : {
            immediate: true,
            deep: true,
            handler() {
                if(!this.propsValue) return ;
          
                this.data = this.$eagleLodash.cloneDeep(this.propsValue)

            },
        },
        data: {
            deep: true,
            handler() {
            
            }
        },
    
    },
    computed: {
        order_no() {
            if(!this.data) return null
            return this.data.order_no
        },
        orderInfo() {
            if(!this.data) return []
            return [
                {text: '訂單狀態', value: this.$t(`order.status.${this.data.status}`)},
                {text: '訂單時間', value: this.$helper.orderTimeFormat(this.data.created_at)},
                {text: '付款方式', value: this.$t(`data.payment_type.${this.data.payment_type}`)}
            ]
        },
        storeInfo() {
            if(!this.data) return []
            if(!this.data.store_info) return []
            const store_info = this.data.store_info
            return [
                {text: '客戶', value: this.data.store_name},
                {text: '聯絡人', value: store_info.contact},
                {text: '電話', value: store_info.phone},
                {text: '地址', value: `${store_info.city}${store_info.district}${store_info.address}`},
                {text: '統一編號', value: store_info.uniform_number},
            ]
        },

    },
    methods: {
   
    },
}
</script>

